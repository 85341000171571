<style>
.add-owner {
    float: right;
}

.ranges {
    max-height: 200px;
    overflow: scroll;
}



.alert-primary {
    width: 100%;
}
</style>

<template>
    <div>

        <!-- Filters -->
        <b-card no-body>
            <b-card-header class="pb-50">
                <h5>Filter Location</h5>
            </b-card-header>
            <b-card-body>

                <b-row v-if="role.id == 5">
                    <b-col cols="3">
                        <b-form-group labe-for="region_id" label="Region">
                            <validation-provider #default="{ errors }" name="Region" rules="">
                                <b-form-select id="region_id" name="region_id" size="md" v-model="filter.region_id"
                                    @change="changeRegion(filter.region_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locations" :key="location.id"
                                        :value="location">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.region_id">{{
                                    serverErrors.region_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale.id">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="warehouse_id" label="Warehouse To">
                            <validation-provider #default="{ errors }" name="warehouse_id" rules="">
                                <b-form-select id="warehouse_id" name="warehouse_id" size="md" v-model="filter.warehouse_id">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                        :value="warehouse.id">
                                        {{ warehouse.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.warehouse_id">{{
                                    serverErrors.warehouse_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="document_id" label="Transaction Code">
                            <validation-provider #default="{ errors }" name="document_id" rules="">
                                <b-form-select id="document_id" name="document_id" size="md" v-model="filter.document_id">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="document in documentsBulk" :key="document.id"
                                        :value="document.id">
                                        {{ document.description }} ({{ document.code }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.document_id">{{
                                    serverErrors.document_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">

                        <div class="mt-10 float-right" style="margin-top: 30px">
                            <b-button variant="primary" size="sm" @click="makeTransfer()">
                                <span class="text-nowrap">Make Transfer</span>
                            </b-button>
                        </div>

                    </b-col>
                </b-row>

                <b-row v-if="role.id != 5">
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="location_id" label="Area">
                            <validation-provider #default="{ errors }" name="Area" rules="">
                                <b-form-select id="location_id" name="location_id" size="md" v-model="filter.location_id"
                                    @change="getAreaMarket(filter.location_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="location in locId.children" :key="location.id"
                                        :value="location.id">
                                        {{ location.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.location_id">{{
                                    serverErrors.location_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="market_id" label="Market Center">
                            <validation-provider #default="{ errors }" name="Market Center" rules="">
                                <b-form-select id="market_id" name="market_id" size="md" v-model="filter.market_id"
                                    @change="getAreaMarketSale(filter.market_id)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="market in markets" :key="market.id" :value="market.id">
                                        {{ market.name.toUpperCase() }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.market_id">{{
                                    serverErrors.market_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3" v-if="locId.id > 0">
                        <b-form-group labe-for="sale_id" label="Sale">
                            <validation-provider #default="{ errors }" name="Sale" rules="">
                                <b-form-select id="sale_id" name="sale_id" size="md" v-model="filter.sale_id"
                                    @change="changeSale($event)">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="sale in sales" :key="sale.id" :value="sale.id">
                                        {{ sale.number }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.sale_id">{{
                                    serverErrors.sale_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="warehouse_id" label="Warehouse To">
                            <validation-provider #default="{ errors }" name="warehouse_id" rules="">
                                <b-form-select id="warehouse_id" name="warehouse_id" size="md" v-model="filter.warehouse_id">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="warehouse in warehouses" :key="warehouse.id"
                                        :value="warehouse.id">
                                        {{ warehouse.name }}
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.warehouse_id">{{
                                    serverErrors.warehouse_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <b-col cols="3">
                        <b-form-group labe-for="document_id" label="Transaction Code">
                            <validation-provider #default="{ errors }" name="document_id" rules="">
                                <b-form-select id="document_id" name="document_id" size="md" v-model="filter.document_id">
                                    <b-form-select-option :value="null">Select...</b-form-select-option>
                                    <b-form-select-option v-for="document in documentsBulk" :key="document.id"
                                        :value="document.id">
                                        {{ document.description }} ({{ document.code }})
                                    </b-form-select-option>
                                </b-form-select>
                                <small class="text-danger">{{ errors[0] }}</small>
                                <small class="text-danger" v-if="serverErrors && serverErrors.document_id">{{
                                    serverErrors.document_id[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                    <b-col cols="3">

                        <div class="mt-10 float-right" style="margin-top: 30px">
                            <b-button variant="primary" size="sm" @click="makeTransfer()">
                                <span class="text-nowrap">Make Transfer</span>
                            </b-button>
                        </div>

                    </b-col>
                </b-row>

                <b-row>

                    <!-- <pre>
                    {{ filter }}
                 </pre> -->
                </b-row>
            </b-card-body>
        </b-card>
        <!-- ./Filters -->

    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
    BForm, BFormGroup,
    BButton, BFormSelect, BFormSelectOption, BFormInput, BDropdown, BDropdownItem,
    BSpinner, BFormCheckbox, BBadge, BAlert, BFormDatepicker
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { required } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted, reactive } from '@vue/composition-api'

import bulkStoreModule from '@/views/cromis/green_shipping/bulk_shipment/bulkStoreModule'
import useBulksList from '@/views/cromis/green_shipping/bulk_shipment/useBulksList'
import Panel from 'primevue/panel';
import Accordion from 'primevue/accordion';
import AccordionTab from 'primevue/accordiontab';

export default {
    props: {},
    components: {
        BRow, BCol, BCard, BCardHeader, BCardBody, BTable, BPagination,
        BForm, BFormGroup,
        BButton, BFormSelect, BFormSelectOption, BFormInput, vSelect, BDropdown, BDropdownItem,
        BSpinner,
        ValidationObserver, ValidationProvider, BFormCheckbox, BBadge, BAlert, Panel, Accordion, AccordionTab, BFormDatepicker
    },
    directives: {},
    setup(props, context) {
        const dataForm = ref(null)
        const myModalPcn = ref(null)
        const myViewModal = ref(null)
        const myRangeModal = ref(null)
        const saving = ref(false)
        const serverErrors = ref(null)
        const totalMass = ref(null)
        const errosOcurs = ref(null)
        const selections = ref([])
        const formSelections = ref([])
        const levels = ref(null)
        const companies = ref([])
        const markets = ref([])
        const requisitionDetail = ref(null)
        const locations = ref([])
        const sales = ref([])
        const children = ref([])
        const tickets = ref([])
        const warehouses = ref([])
        const documents = ref([])
        const documentsBulk = ref([])
        const now = new Date()
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
        const minDate = new Date(today)
        const requisition = ref({
            requisition_id: null,
            type: null,
            ranges: [
                {
                    start: null,
                    end: null,
                }
            ]
        })
        const filter = ref({
            region_id: null,
            location_id: null,
            market_id: null,
            sale_id: null,
            document_id: null,
            tickets:[],
            warehouse_id:null
        })



        const locId = ref(JSON.parse(localStorage.getItem('userData')).location)
        const role = ref(JSON.parse(localStorage.getItem('userData')).roles[0])
        console.log(locId)





        const CROMIS_STORE_MODULE_NAME = 'cromis-bulk'
        // Register module
        if (!store.hasModule(CROMIS_STORE_MODULE_NAME)) store.registerModule(CROMIS_STORE_MODULE_NAME, bulkStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_STORE_MODULE_NAME)
        })

        onMounted(async () => {


            await store.dispatch('cromis-bulk/locations')
                .then(response => {
                    locations.value = response.data.locations

                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-bulk/documents')
                .then(response => {
                    // Assuming response.data.documents contains your documents array
                    documents.value = response.data.documents;
                    // Correct the filter function
                    documents.value.forEach(ele => {
                        if(ele.transactionCode == 0){
                            documentsBulk.value.push(ele)
                        }
                   });

                    console.log(documentsBulk.value);
                })
                .catch(error => {
                    console.log('got error', error);
                });
        })

        const populateSelections = (locations) => {
            for (let i = 0; i < locations.length; i++) {
                for (let x = 0; x < selections.value.length; x++) {
                    if (locations[i].level.name.toLowerCase() === selections.value[x].level) {
                        selections.value[x].locations.push(locations[i])

                        if (x === 0) {
                            formSelections.value[x].locations.push(locations[i])
                        }
                    }
                }
            }
        }

        const {
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,

            // Filers
            resolveStatusVariant,
            resolveReceivedVariant,
            locationFilter,
            marketFilter,
            saleFilter,
            societyFilter
        } = useBulksList()

        // Form Methods


        const getTotalMass = (massArray) => {
            totalMass.value = 0;
            // console.log(massArray)
            // massArray.forEach(ele => {
            //     totalMass.value += ele.mass;
            // });

            for (let index = 0; index < massArray.length; index++) {
                totalMass.value = massArray[index].value;

            }

            return totalMass.value;
        }


        const makeTransfer = async () => {
            filter.value.sale_id = filter.value.sale_id.id
            console.log(filter.value.sale_id)
            let data = {
                "warehouse_id": filter.value.warehouse_id,
                "tickets": filter.value.tickets,
                "document_id": filter.value.document_id,
            }
            console.log(data)


            await store.dispatch('cromis-bulk/update',  { id: filter.value.warehouse_id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Bulk transfer has been made successfuly!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const handleCheckboxChange = async (event, id) => {

            console.log(event)
            let payload = {
                "received": event
            }

            await store.dispatch('cromis-bulk/update', { id: id, data: payload })
                .then(response => {

                    console.log(response)
                    refetch()
                    saving.value = false


                    context.root.$swal({
                        icon: 'success',
                        text: `Changes to PCN has been saved successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    console.log(error)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }


        const getAreaMarket = async () => {
            locationFilter.value = filter.value.location_id
            await store.dispatch('cromis-bulk/markets', { location_id: filter.value.location_id })
                .then(response => {
                    markets.value = response.data.markets

                    // console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })


                await store.dispatch('cromis-bulk/warehouses', { location_id: filter.value.location_id })
                .then(response => {
                    warehouses.value = response.data.warehouses

                    // console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const getAreaMarketSale = async () => {
            marketFilter.value = filter.value.market_id
            await store.dispatch('cromis-bulk/sales', { market_id: filter.value.market_id })
                .then(response => {
                    sales.value = response.data.sales
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
        }

        const changeSociet = async (id) => {

            societyFilter.value = id
        }

        const changeSale = async (event) => {

            console.log(event)

            await store.dispatch('cromis-bulk/tickets', { sale_id: event })
                .then(response => {
                    response.data.tickets.forEach(ele => {
                        tickets.value.push({"barcode": ele.barcode})
                    });
                    filter.value.tickets = tickets
                    console.log(filter.value)
                    console.log(response)
                })
                .catch(error => {
                    console.log('got error', error)
                })
            // filter.value.sale_id = event.id
        }

        const changeRegion = async (event) => {

            locId.value = event
            console.log(locId.value)
            locationFilter.value = event.id
            // filter.value.sale_id = event.id
        }




        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            handleCreate()
        }



        const handleCreate = async () => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }



        }



        const handleApprove = async (item) => {

            saving.value = true

            let data = {
                "approved": true
            }

            console.log(item)
            console.log(data)

            await store.dispatch('cromis-bulk/update', { id: item.id, data: data })
                .then(response => {
                    refetch()
                    saving.value = false

                    context.root.$swal({
                        icon: 'success',
                        text: `Approved has been made successfully!`,
                        showConfirmButton: true,
                        timer: 3000,
                        customClass: {
                            confirmButton: 'btn btn-success',
                        },
                        buttonsStyling: false,
                    })
                })
                .catch(error => {
                    saving.value = false
                    errosOcurs.value = error.response.data
                    console.log(error.response)
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors

                        console.log(serverErrors)
                    }
                    else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }



        const remove = async (id) => {
            await store.dispatch('cromis-bulk/remove', id)
                .then(response => {
                    refetch()
                })
                .catch(error => {
                    context.root.$swal({
                        icon: 'error',
                        title: 'Server Error',
                        text: 'Something went wrong. See tech support',
                        showConfirmButton: true,
                        customClass: {
                            confirmButton: 'btn btn-danger',
                        },
                        buttonsStyling: true,
                    })
                })
        }

        return {
            // Data
            selections,
            formSelections,
            levels,
            children,
            requisition,

            selectedOwner: null,
            clientName: JSON.parse(localStorage.getItem('userData')).client,
            options: [
                { value: null, text: '-- Select Box Type --' },
                { value: '1', text: 'Bale Ticket' },
                { value: '2', text: 'Luggage' },
            ],
            fetch,
            columns,
            perPage,
            currentPage,
            totalRecords,
            from,
            to,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            refetch,
            resolveStatusVariant,
            resolveReceivedVariant,

            // Methods
            getAreaMarket,
            required,
            saving,
            serverErrors,
            dataForm,
            myModalPcn,
            myViewModal,
            requisitionDetail,
            filter,
            markets,
            locations,
            sales,
            changeSale,
            changeSociet,
            changeRegion,
            myRangeModal,
            locationFilter,
            marketFilter,
            saleFilter,
            societyFilter,
            submit,
            isFormValid,
            handleCreate,
            companies,
            errosOcurs,
            locId,
            role,
            tickets,
            documents,
            documentsBulk,
            getAreaMarketSale,
            warehouses,
            makeTransfer
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>